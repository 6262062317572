import { Component, OnInit } from '@angular/core';
import {ApiRestService} from "../_services/api-rest.service";
import {ActivatedRoute} from "@angular/router";
import {Spedizione} from "../Models/Spedizione";

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {
  Spedizione: any;
  Tracking: any;

  constructor(
    private apiRest: ApiRestService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      /*this.apiRest.getSpedizione({id: parseInt(data['id'])}).subscribe(data=> {
        this.Spedizione = data*/
        this.apiRest.tracking({id: data.id}).subscribe(tracking=>this.Tracking=tracking);
     /* });*/

    });
  }
  GoToTracking(spedizione: any) {
    window.open(spedizione.link, "_blank");

  }

}
