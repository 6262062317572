import { LoggedUser } from './../../Login/Models/LoggedUser';
import { AuthenticationService } from './../../Login/_services/authentication.service';
import { environment } from './../../../environments/environment';
import { Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RouteInfo } from './vertical-sidebar.metadata';
import { ROUTES } from './vertical-menu-items';
import {Permission} from "../../Login/_guards/Permission";


@Injectable({
    providedIn: 'root'
})
export class VerticalSidebarService {
  constructor(private authsvc: AuthenticationService, private permission: Permission){
    this.authsvc.currentUser.subscribe(data=>{
      if(data && data != null) {
        this.MENUITEMS = [];
        this.MENUITEMS = this.buildMenu(data);
        this.items.next(Object.assign({},this).MENUITEMS);
      }
    });
  }
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public fullScreen: boolean = false;
  MENUITEMS: RouteInfo[] = [];
  items = new BehaviorSubject<RouteInfo[]>([]);
  readonly $items = this.items.asObservable();
  isAdmin(data: LoggedUser): boolean {
    return data.ruoliObject.findIndex(x=> x.id == "A" || x.id == "SA" || x.id == "AF") > -1;
  }
  buildMenu(data: LoggedUser): RouteInfo[] {
    var Output: RouteInfo[] = [];
    Output.push({
      path: "",
      title: "Menu",
      icon: "",
      class: "has-arrow",
      label: "Menu",
      labelClass:"",
      extralink: true,
      submenu: [],
    });
    if(environment.Shared && this.permission.isPermitted("menu-dashboard") && this.permission.isntOperativeRoles(['A','BM']))
      Output.push({
        path: "dashboard",
        title: environment.SharedLabel,
        icon: "mdi mdi-gauge",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [],
      });
    if(environment.Shared && this.permission.isPermitted("menu-dashboard") && this.permission.isOperativeRoles(['A','BM','SA']))
      Output.push({
        path: "dashboard/laera",
        title: environment.SharedLabel,
        icon: "mdi mdi-gauge",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [],
      });
    if (environment.Admin && environment.Personale && this.permission.isPermitted("menu-persone")&& this.permission.isPermitted("agenzia") && this.permission.isntOperativeRole('BM')){
      Output.push({
        path: "/listautenti/personale",
        title: environment.PersonaleLabel,
        icon: "mdi mdi-account-key",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [],
      })
    }if (environment.Admin && environment.Clienti && this.permission.isPermitted("menu-clienti") && this.permission.isntOperativeRole('A')){
      Output.push({
        path: "/listautenti/aziende",
        title: environment.ClientiLabel,
        icon: "mdi mdi-account-multiple",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [],
      });
    }
    if (environment.Admin && environment.Clienti && this.permission.isPermitted("menu-clienti") && this.permission.isntOperativeRoles(['A', 'BM'])){
      Output.push({
        path: "/listautenti/fermoposta",
        title: "Fermoposta",
        icon: "mdi mdi-box",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [],
      });
    }
    if (environment.Interventi && this.permission.isPermitted("menu-fasi"))
      Output.push({
        path: "",
        title: environment.Interventilabel,
        icon: "mdi mdi-wrench",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "has-arrow",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [
          {
            path: "interventi/interventi",
            title: "Lista Interventi",
            icon: "",
            class: "",
            label: "",
            labelClass: "",
            extralink: false,
            submenu: []
          },
          {
            path: "interventi/pianificazione",
            title: "Pianificazione",
            icon: "",
            class: "",
            label: "",
            labelClass: "",
            extralink: false,
            submenu: []
          }
        ],
      });
    if (environment.Prima_Nota && this.permission.isPermitted("menu-primaNota"))
      Output.push({
        path: "",
        title: environment.Prima_NotaLabel,
        icon: "mdi mdi-cart",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "has-arrow",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [
          {
            path: "primanota/primanota",
            title: "Categorie",
            icon: "",
            class: "",
            label: "",
            labelClass: "",
            extralink: false,
            submenu: []
          },
          {
            path: "primanota/lista-spese",
            title: "Lista",
            icon: "",
            class: "",
            label: "",
            labelClass: "",
            extralink: false,
            submenu: []
          }
        ],
      });
      if(environment.Documenti && this.permission.isPermitted("menu-documenti"))
      Output.push( {
        path: "progetti/documenti",
        title: environment.DocumentiLabel,
        icon: "mdi mdi-file-document",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [],
      });
    if(environment.Prodotti && this.permission.isPermitted("menu-prodotti"))
      Output.push({
        path: "progetti/prodotti",
        title: environment.ProdottiLabel,
        icon: "mdi mdi-barcode-scan",
        class: "",
        label: "",
        labelClass:"",
        extralink: false,
        submenu: [],
      });
    if (environment.Parti && this.permission.isPermitted("menu-parti")){
      Output.push({
        path: "progetti/parti",
        title: environment.PartiLabel,
        icon: "mdi mdi-barcode",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [],
      });
    }
    if (environment.Parti && this.permission.isPermitted("menu-variazioni")){
      Output.push({
        path: "progetti/parti/categorie-list",
        title: "Categorie " + environment.PartiLabel,
        icon: "mdi mdi-cart",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [],
      });
    }
    if (environment.Progetti && this.permission.isPermitted("menu-progetti"))
      Output.push({
        path: "progetti/progetti",
        title: environment.ProgettiLabel,
        icon: "mdi mdi-calendar-multiple-check",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: [],
      });
    if (environment.Settings && this.permission.isPermitted("menu-settings"))
      Output.push({
        path: "settings/settings",
        title: environment.SettingsLabel,
        icon: "mdi-settings",
        class: "",
        label: "",
        labelClass:"",
        extralink: false,
        submenu: [],
      });

    if(environment.Spedizioni && this.permission.isPermitted("menu-spedizione")) {
      Output.push({
        path: "spedizioni/lista",
        title: environment.SpedizioniLabel,
        icon: "mdi mdi-airplane",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      });
    }
    if(environment.Spedizioni && this.permission.isPermitted("menu-spedizione") && this.permission.isntOperativeRole('A'))
    {
      Output.push({
        path: "spedizioni/pratiche",
        title: "Pratiche",
        icon: "mdi mdi-airplane",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      });
    }
    if(environment.Spedizioni && this.permission.isPermitted("menu-spedizione") && this.permission.isntOperativeRoles(['A', 'BM']))
    {
      Output.push({
        path: "spedizioni/lista?fattura=1",
        title: "FATTURE",
        icon: "mdi mdi-airplane",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      });
    }

    if (environment.Stampe && this.permission.isPermitted("menu-stampe"))
      Output.push({
        path: "stampe/templates",
        title: environment.StampeLabel,
        icon: "mdi mdi-printer",
        class: "has-arrow",
        label: "",
        labelClass:"",
        extralink: false,
        submenu: [
          {
            path: "stampe/templates",
            title: "Templates",
            icon: "",
            class: "",
            label: "",
            labelClass: "",
            extralink: false,
            submenu: []
          }, {
            path: "stampe/marker",
            title: "Marker",
            icon: "",
            class: "",
            label: "",
            labelClass: "",
            extralink: false,
            submenu: []
          }
        ],
      });
    if (environment.Task && this.permission.isPermitted("menu-task"))
      Output.push({
        path: "task/riepilogogiornaliero",
        title: environment.TaskLabel,
        icon: "mdi mdi-currency-eur",
        class: "",
        label: "",
        labelClass:"",
        extralink: false,
        submenu: [],
      });
    if (environment.Corrieri && this.permission.isPermitted("menu-corrieri"))
      Output.push({
        path: "spedizioni/corrieri",
        title: environment.CorrieriLabel,
        icon: "mdi mdi-box",
        class: "",
        label: "",
        labelClass:"",
        extralink: false,
        submenu: [],
      });
    // if (environment.Scadenzario && this.permission.isPermitted("menu-scadenzario"))
    //   Output.push({
    //     path: "scadenzario/scadenzario",
    //     title: "Scadenze",
    //     icon: "mdi mdi-calendar",
    //     class: "",
    //     label: "",
    //     labelClass:"",
    //     extralink: false,
    //     submenu: [],
    //   });
    if (environment.Scadenzario && this.permission.isPermitted("menu-scadenzario"))
      Output.push({
        path: "scadenzario/lista",
        title: "Lista Scadenze",
        icon: "mdi mdi-calendar",
        class: "",
        label: "",
        labelClass:"",
        extralink: false,
        submenu: [],
      });
    return Output;

  }
}
