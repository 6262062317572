<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="{'show-sidebar': showMobileMenu}" [dir]="options.dir"
    [attr.data-theme]="options.theme" [attr.data-layout]="options.layout" [attr.data-sidebartype]="options.sidebartype"
    [attr.data-sidebar-position]="options.sidebarpos" [attr.data-header-position]="options.headerpos"
    [attr.data-boxed-layout]="options.boxed">
    <header class="topbar"  [attr.data-navbarbg]="options.navbarbg">
        <nav class="d-flex top-navbar navbar-expand-lg"
            [ngClass]="(options.navbarbg=='skin6')?'navbar-light':'navbar-dark'">
            <div class="navbar-header" [ngClass]="(expandLogo)?'expand-logo':''" [attr.data-logobg]="options.logobg">
                <!-- This is for the sidebar toggle which is visible on mobile only -->
                <a (click)="showMobileMenu = !showMobileMenu"
                    class="nav-toggler waves-effect waves-light d-block d-lg-none" href="javascript:void(0)">
                    <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
                </a>
                <a class="navbar-brand" href="/">
                    <b class="logo-icon">
                        <img [src]="options.logoPath" alt="homepage" style="max-height: 6vh;" class="dark-logo" />
                        <img [src]="options.logoPath" alt="homepage" style="width: 100%; height: 100%;" class="light-logo" />
                    </b>
                </a>
                <a class="topbartoggler d-block d-lg-none waves-effect waves-light" href="javascript:void(0)"
                    (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i class="ti-more"></i>
                </a>
            </div>
            <div class="navbar-collapse collapse" id="navbarSupportedContent" [attr.data-navbarbg]="options.navbarbg"
                [ngbCollapse]="!isCollapsed">
                <!-- Horizontal Header -->
                <div *ngIf="options.layout==='horizontal' else vheader" class="w-100">
                    <app-horizontal-navigation class="w-100 d-flex align-items-center justify-content-between">
                    </app-horizontal-navigation>
                </div>

                <!-- Vertical Header -->
                <ng-template #vheader>
                    <div class="w-100">
                        <app-vertical-navigation (toggleSidebar)="toggleSidebarType()">
                        </app-vertical-navigation>
                    </div>
                </ng-template>
            </div>
        </nav>
    </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar" [attr.data-sidebarbg]="options.sidebarbg" (mouseover)="Logomouseover()" (mouseout)="Logomouseover()">
        <!-- Sidebar scroll-->
        <div *ngIf="options.layout==='horizontal'; else vlayout">
            <app-horizontal-sidebar></app-horizontal-sidebar>
        </div>

        <!-- Horizontal Header -->
        <ng-template #vlayout>
            <div class="scroll-sidebar" [perfectScrollbar]="config">
                <app-vertical-sidebar [showClass]='showMobileMenu' (notify)='handleClick($event)'>
                </app-vertical-sidebar>
            </div>
        </ng-template>
        <!-- End Sidebar scroll-->
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <app-breadcrumb></app-breadcrumb>
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid " style="height: 90%;     margin-top: 50px;">
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
    <aside *ngIf="hasCustomizer"  class="customizer" [ngClass]="{'show-service-panel': showSettings}">
        <a href="javascript:void(0)" class="btn-dark btn btn-circle btn-lg service-panel-toggle"
            (click)="showSettings = !showSettings">
            <i class="fa fa-spin fa-cog"></i>
        </a>
        <div class="customizer-body custom-pills" [perfectScrollbar]="config">
            <ngb-tabset type="pills" [justify]="tabStatus">
                <ngb-tab>
                    <ng-template ngbTabTitle>
                        <i class="mdi mdi-wrench font-20"></i>
                    </ng-template>
                    <ng-template ngbTabContent>
                        <div class="p-15 border-bottom">
                            <!-- Sidebar -->
                            <h5 class="font-medium m-b-20 m-t-10">Layout Settings</h5>
                            <div class="custom-control custom-checkbox m-t-10">
                                <input type="checkbox" class="custom-control-input" name="layout" id="layout"
                                    [attr.checked]="(options.layout=='horizontal') ? 'checked' : null"
                                    (change)="options.layout = (options.layout == 'vertical' ? 'horizontal' : 'vertical')"
                                    (click)="options.boxed = (options.boxed == 'full' ? 'boxed' : 'full');">
                                <label class="custom-control-label font-normal" for="layout">Horizontal</label>
                            </div>
                            <div class="custom-control custom-checkbox m-t-10">
                                <input type="checkbox" class="custom-control-input" name="theme-view" id="theme-view"
                                    [attr.checked]="(options.theme=='dark') ? 'checked' : null"
                                    (change)="options.theme = (options.theme == 'light' ? 'dark' : 'light');">
                                <label class="custom-control-label" for="theme-view">Dark Theme</label>
                            </div>
                            <div class="custom-control custom-checkbox m-t-10">
                                <input type="checkbox" class="custom-control-input" name="sidebar-position"
                                    id="sidebar-position"
                                    [attr.checked]="(options.sidebarpos=='fixed') ? 'checked' : null"
                                    (change)="options.sidebarpos = (options.sidebarpos == 'fixed' ? 'absolute' : 'fixed');">
                                <label class="custom-control-label" for="sidebar-position">Fixed Sidebar</label>
                            </div>
                            <div class="custom-control custom-checkbox m-t-10">
                                <input type="checkbox" class="custom-control-input" name="header-position"
                                    id="header-position"
                                    [attr.checked]="(options.headerpos=='fixed') ? 'checked' : null"
                                    (change)="options.headerpos = (options.headerpos == 'fixed' ? 'absolute' : 'fixed');">
                                <label class="custom-control-label" for="header-position">Fixed Header</label>
                            </div>
                            <div class="custom-control custom-checkbox m-t-10">
                                <input type="checkbox" class="custom-control-input" name="boxed-layout"
                                    id="boxed-layout" [attr.checked]="(options.boxed=='boxed') ? 'checked' : null"
                                    (change)="options.boxed = (options.boxed == 'full' ? 'boxed' : 'full');">
                                <label class="custom-control-label" for="boxed-layout">Boxed Layout</label>
                            </div>
                            <div class="custom-control custom-checkbox m-t-10">
                                <input type="checkbox" class="custom-control-input" name="rtl-layout" id="rtl-layout"
                                    [attr.checked]="(options.dir=='rtl') ? 'checked' : null"
                                    (change)="options.dir = (options.dir == 'rtl' ? 'ltr' : 'rtl');">
                                <label class="custom-control-label" for="rtl-layout">RTL</label>
                            </div>
                        </div>
                        <div class="p-15 border-bottom" *ngIf="options.layout==='vertical'">
                            <!-- Sidebar -->
                            <h5 class="font-medium m-b-20 m-t-10">Sidebar Types</h5>
                            <div class="custom-control custom-radio m-t-10">
                                <input type="radio" class="custom-control-input" name="sidebar"
                                    [(ngModel)]="options.sidebartype" value="mini-sidebar" id="minisidebar"
                                    (change)="options.sidebartype = 'mini-sidebar'">
                                <label class="custom-control-label" for="minisidebar">Mini Sidebar</label>
                            </div>
                            <div class="custom-control custom-radio m-t-10">
                                <input type="radio" class="custom-control-input" name="sidebar"
                                    [(ngModel)]="options.sidebartype" value="iconbar" id="iconsidebar"
                                    (change)="options.sidebartype = 'iconbar'">
                                <label class="custom-control-label" for="iconsidebar">Icon Sidebar</label>
                            </div>
                            <div class="custom-control custom-radio m-t-10">
                                <input type="radio" class="custom-control-input" name="sidebar"
                                    [(ngModel)]="options.sidebartype" value="overlay" id="overlaysidebar"
                                    (change)="options.sidebartype = 'overlay'">
                                <label class="custom-control-label" for="overlaysidebar">Overlay Sidebar</label>
                            </div>
                            <div class="custom-control custom-radio m-t-10">
                                <input type="radio" class="custom-control-input" name="sidebar"
                                    [(ngModel)]="options.sidebartype" value="full" id="fullsidebar"
                                    (change)="options.sidebartype = 'full'">
                                <label class="custom-control-label" for="fullsidebar">Full Sidebar</label>
                            </div>
                        </div>
                        <div class="p-15 border-bottom" *ngIf="options.layout==='vertical'">
                            <!-- Logo BG -->
                            <h5 class="font-medium m-b-20 m-t-10">Logo Backgrounds</h5>
                            <ul class="theme-color">
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin1"
                                        (click)="options.logobg = 'skin1'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin2"
                                        (click)="options.logobg = 'skin2'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin3"
                                        (click)="options.logobg = 'skin3'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin4"
                                        (click)="options.logobg = 'skin4'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin5"
                                        (click)="options.logobg = 'skin5'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin6"
                                        (click)="options.logobg = 'skin6'"></a>
                                </li>
                            </ul>
                            <!-- Logo BG -->
                        </div>
                        <div class="p-15 border-bottom">
                            <!-- Navbar BG -->
                            <h5 class="font-medium m-b-20 m-t-10">Navbar Backgrounds</h5>
                            <ul class="theme-color">
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin1"
                                        (click)="options.navbarbg = 'skin1'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin2"
                                        (click)="options.navbarbg = 'skin2'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin3"
                                        (click)="options.navbarbg = 'skin3'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin4"
                                        (click)="options.navbarbg = 'skin4'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin5"
                                        (click)="options.navbarbg = 'skin5'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin6"
                                        (click)="options.navbarbg = 'skin6'"></a>
                                </li>
                            </ul>
                            <!-- Navbar BG -->
                        </div>
                        <div class="p-15 border-bottom">
                            <!-- Logo BG -->
                            <h5 class="font-medium m-b-20 m-t-10">Sidebar Backgrounds</h5>
                            <ul class="theme-color">
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin1"
                                        (click)="options.sidebarbg = 'skin1'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin2"
                                        (click)="options.sidebarbg = 'skin2'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin3"
                                        (click)="options.sidebarbg = 'skin3'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin4"
                                        (click)="options.sidebarbg = 'skin4'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin5"
                                        (click)="options.sidebarbg = 'skin5'"></a>
                                </li>
                                <li class="theme-item">
                                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin6"
                                        (click)="options.sidebarbg = 'skin6'"></a>
                                </li>
                            </ul>
                            <!-- Logo BG -->
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </aside>
    <div class="chat-windows"></div>
</div>
