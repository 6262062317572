// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://www.laera.cloud/api',
  baseUrl: '/spedizioni/lista',
  logoPath: 'assets/images/LogoLaera.png',
  logoBg: 'skin6', // six possible values: skin(1/2/3/4/5/6),
  navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
  sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
  userCircleColor: "#ffffff",
  theme: 'light',
  dir: 'ltr', // two possible values: ltr, rtl
  layout: 'vertical', // fixed value. shouldn't be changed.
  sidebartype: 'overlay', // four possible values: full, iconbar, overlay, mini-sidebar
  sidebarpos: 'absolute', // two possible values: fixed, absolute
  headerpos: 'fixed', // two possible values: fixed, absolute
  boxed: 'full', // two possible values: full, boxed
  Admin: true,
  Personale: true,
  PersonaleLabel: "Agenzie",
  Clienti: true,
  ClientiLabel: "Clienti",
  ClientiAddOn: [false, false, true, false, false, false],//  info aggiuntive/ sedi aggiuntive / attivita / geolocalizzazione / ruolo/ tipo
  Interventi: false,
  Interventilabel: "Interventi",
  Prima_Nota: false,
  Prima_NotaLabel: "Spese",
  Progetti: false,
  ProgettiLabel:"Progetti",
  Prodotti: false,
  ProdottiLabel:"Prodotti",
  Documenti: false,
  DocumentiLabel:"Documenti",
  Scadenzario: true,
  ScadenzarioLabel:"Scadenzario",
  Spedizioni: true,
  SpedizioniLabel:"LISTA SPEDIZIONI",
  Corrieri: true,
  CorrieriLabel:"Corrieri",
  Stampe: false,
  StampeLabel:"Stampe",
  Task: false,
  TaskLabel:"Incasssi",
  Shared: true,
  SharedLabel:"Dashboard",
  VersioneCarbotti: false,
  PartiProdotto: false,
  Parti: false,
  PartiLabel: "Parti",
  PartiAddOn: [false],//  categorie associate
  Settings: true,
  SettingsLabel:"Settings",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
