import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiPathsService {

constructor() { }
 /**
   * URL di base dell'API
   */
  private baseAPI = environment.apiUrl;
  private socketURL = '';
  private socketPort = '';

  public getBaseAPI = () => this.baseAPI //+ "/api";
  public getSocketURL = () => this.socketURL;
  public getSocketPort = () => this.socketPort;
}
