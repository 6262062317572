<li class="nav-item " ngbDropdown placement="bottom-right">
  <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <div class="d-flex no-block align-items-center p-10  m-b-3 rounded-pill" [style.background-color]="bgcolor" style="display: unset">
      <div class="rounded">
        <i-feather name="user" class="align-items-center feather-lg"></i-feather>
        <!-- <img src="assets/images/users/1.jpg" alt="user" class="rounded" width="70"> -->
      </div>
    </div>
  </a>
  <div class="dropdown-menu-right user-dd " ngbDropdownMenu>
    <div class="d-flex no-block align-items-center p-15  m-b-10">
      <div class="rounded">
        <i-feather name="user" class="feather-lg"></i-feather>
        <!-- <img src="assets/images/users/1.jpg" alt="user" class="rounded" width="70"> -->
      </div>
      <div class="m-l-10">
        <h4 class="m-b-0 font-15">{{currentUser.nome }} {{currentUser.cognome}}</h4>
        <!-- <p class="text-muted m-b-0 font-12">varun@gmail.com</p> -->
        <!-- <a href="javascript:void(0)" class="btn btn-danger btn-rounded btn-sm m-t-5">View Profile</a> -->
      </div>
    </div>
    <a class="dropdown-item" (click)="Profilo()" href="javascript:void(0)">
      <i class="ti-settings m-r-5 m-l-5"></i> Profilo</a>
    <!--<a class="dropdown-item" href="javascript:void(0)">
      <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
    <a class="dropdown-item" href="javascript:void(0)">
      <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" href="javascript:void(0)">
      <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
    <div class="dropdown-divider"></div> -->
    <a class="dropdown-item" (click)="LogOut()" >
      <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
  </div>
</li>
