<!--<div class="error-box">-->
<!--  <div class="error-body text-center">-->
<!--    <h1 class="error-title text-danger">404</h1>-->
<!--    <h3 class="text-uppercase error-subtitle">PAGE NOT FOUND !</h3>-->
<!--    <p class="text-muted m-t-30 m-b-30">YOU SEEM TO BE TRYING TO FIND HIS WAY HOME</p>-->
<!--    <a href="#/dashboard/classic" class="btn btn-danger btn-rounded waves-effect waves-light m-b-40">Back to home</a>-->
<!--  </div>-->
<!--</div>-->

<div class="page-wrapper2">
  <div class="page-breadcrumb">
    <div class="d-md-flex align-items-center">
      <h3 class="page-title">La tua Spedizione:</h3>
      <div class="ml-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
<!--            <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">-->
<!--              <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">-->
<!--                <a href='javascript:void(0)'>{{url.title}}</a>-->
<!--              </li>-->
<!--              <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li>-->
<!--            </ng-template>-->
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="container-fluid" style="height: 90%; padding: 20px;">
<!--    &nbsp;<span style="font-weight: bold">Data di Tracking:</span>&nbsp;{{Tracking?.data_inizio_prevista | date: 'dd/MM/yyyy HH:mm'}}<br>-->
<!--    &nbsp;<span style="font-weight: bold">Corriere:</span>&nbsp;{{Tracking?.corriereObject?.nominativo}}<br>-->
    <div class="card">
      <div class="card-body">
        <div class="row" *ngIf="Tracking">
          <div class="col-lg-6">
            <span style="font-weight: bold; font-size: 16px;"> Mittente:</span> <br>
            &nbsp;<span style="font-weight: bold">Nominativo:</span>&nbsp;{{Tracking.mittente.nome}}<br>
            &nbsp;<span style="font-weight: bold">Indirizzo:</span>&nbsp;{{Tracking.mittente.indirizzo}}<br>
            &nbsp;<span style="font-weight: bold">Città:</span>&nbsp;{{Tracking.mittente.citta}}<br>
            &nbsp;<span style="font-weight: bold">Provincia:</span>&nbsp;{{Tracking.mittente.provincia}}<br>
            &nbsp;<span style="font-weight: bold">CAP:</span>&nbsp;{{Tracking.mittente.cap}}
          </div>
          <div class="col-lg-6">
            <span style="font-weight: bold; font-size: 16px;"> Destinatario:</span> <br>
            &nbsp;<span style="font-weight: bold">Nominativo:</span>&nbsp;{{Tracking.destinatario.nome}}<br>
            &nbsp;<span style="font-weight: bold">Indirizzo:</span>&nbsp;{{Tracking.destinatario.indirizzo}}<br>
            &nbsp;<span style="font-weight: bold">Città:</span>&nbsp;{{Tracking.destinatario.citta}}<br>
            &nbsp;<span style="font-weight: bold">Provincia:</span>&nbsp;{{Tracking.destinatario.provincia}}<br>
            &nbsp;<span style="font-weight: bold">CAP:</span>&nbsp;{{Tracking.destinatario.cap}}
          </div>
        </div>
        <h2 *ngIf="Tracking?.dataConsegna?.length>0">In consegna il {{Tracking.dataConsegna | date: 'dd/MM/yyyy HH:mm' }}</h2>
        <label class="container" *ngIf="Tracking?.link?.length>0" style="margin-left: 0; width: auto; margin-right: auto; padding: 0 ">
          <button (click)='GoToTracking(Tracking)' style="margin-left: 0; width: auto; margin-right: 0; "  type="button" class="btn btn-ciano">
            <i class="fas fa-box-open " style=" font-size: 1.8em; color: #3dade6;"></i>
          </button>
          VAI AL TRACKING
        </label>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <ul class="timeline timeline-left">
          <li *ngFor="let item of Tracking?.steps" class="timeline-inverted timeline-item">
            <div class="timeline-badge success">
              <i  class="fas fa-check-circle"></i>
<!--              <span class="font-12">{{item.data | date: 'dd/MM/yyyy HH:mm'}}</span>-->
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="timeline-title"><b style="font-size: 14px">{{item?.data | date: 'dd/MM/yyyy HH:mm'}}</b>{{item?.luogo?"&nbsp;-&nbsp;":""}}{{item?.luogo}}&nbsp;-&nbsp;<i>{{item?.stato}}</i></h4>
<!--                <p>-->
<!--                  <small class="text-muted">-->
<!--                    <i class="fa fa-clock-o"></i> {{item.data | date: 'dd/MM/yyyy HH:mm'}}</small>-->
<!--                </p>-->
<!--              </div>-->
<!--              <div class="timeline-body">-->
<!--                <p>{{item.stato}}</p>-->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>
