<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-lg-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="ti-menu"></i>
        </a>
    </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
      <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2"
         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="mdi mdi-bell-outline font-weight-bold font-24"></i>
        <div class="notify" *ngIf="notifiche && notifiche > 0"> <span class="heartbit"></span> <span class="point"></span> </div>
      </a>
      <div class="dropdown-menu dropdown-menu-right mailbox" aria-labelledby="2" style="z-index: 101" ngbDropdownMenu>
        <ul class="list-style-none">
          <li class="border-bottom">
            <div class="drop-title bg-info text-white">Notifiche</div>
          </li>
          <li>
            <div class="message-center message-body" [perfectScrollbar]="config" *ngIf="notifiche && notifiche > 0">
              <!-- Message -->
              <a href="/scadenzario/scadenzario" class="message-item">
                <span class="btn btn-circle btn-success" style="padding: 0">
                  <i class="far fa-bell font-24"></i>
                </span>
                <span class="mail-contnet">
                    <h5 class="message-title">Hai {{notifiche}} {{notifiche && notifiche > 1 ? "scadenze" : "scadenza"}}</h5>
                </span>
              </a>
            </div>
          </li>
          <li>
            <a class="nav-link text-center text-dark link" href="/scadenzario/scadenzario" *ngIf="notifiche && notifiche > 0">
              <b>Vai al Calendario  </b>
              <i class="fa fa-angle-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <app-user-dropdown style=" height: auto; display: flex; align-items: center; "></app-user-dropdown>
</ul>
