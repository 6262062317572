import { RitornoPaginazione } from 'src/app/shared/models/RitornoPaginazione';
import { Corriere } from './../Models/Corriere';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPathsService } from 'src/app/api-paths.service';
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';
import { map } from 'rxjs/operators';
import { Spedizione } from '../Models/Spedizione';
import { ClassicType } from "../../shared/models/ClassicType";
@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  header() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    })
  }
  headerforfile() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:  this.authenticationService.currentUserValue ? `Bearer ${this.authenticationService.currentUserValue?.access_token}`: '',
      }),
    };
  }
  getCorrieri(value?): Observable<Corriere[]> {
    return this.http.post<RitornoPaginazione<Corriere[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/corrieri/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        return data.data;
      }));
  }
  getCorrieriList(value?): Observable<RitornoPaginazione<Corriere[]>> {
    return this.http.post<RitornoPaginazione<Corriere[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/corrieri/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getCorrieriAll(value?): Observable<RitornoPaginazione<Corriere[]>> {
    return this.http.post<RitornoPaginazione<Corriere[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/corrieri/all`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  UpdateCorriere(value?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/corrieri/update`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  AddCorriere(value?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/corrieri/add`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  DeleteCorriere(value?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/corrieri/delete`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  RestoreCorriere(value?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/corrieri/restore`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  TipiCorriere(): Observable<any> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/corrieri/types`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getNazioni(cerca?: string): Observable<ClassicType[]> {
    return this.http.post<RitornoPaginazione<ClassicType[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/nazioni`,
      JSON.stringify({nome: cerca}),
      this.options()).pipe(map((data) => {
      return data.data;
    }));
  }
  getStati(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(`${this.apiPaths.getBaseAPI()}/task/spedizione/stati`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getListaSpedizioni(value): Observable<RitornoPaginazione<Spedizione[]>> {
    if(!value.pratiche)
    return this.http.post<RitornoPaginazione<Spedizione[]>>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        return data;
      }));
    else
      return this.http.post<RitornoPaginazione<Spedizione[]>>(
        `${this.apiPaths.getBaseAPI()}/task/spedizione/pratiche`,
        JSON.stringify(value),
        this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getSpedizione(value): Observable<Spedizione> {
    return this.http.post<Spedizione>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/get`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getNextId(): Observable<number> {
    return this.http.get<number>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/progressivo`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  addSpedizione(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  createSpedizione(): Observable<any> {
    return this.http.get<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/create`,
      this.options()).pipe();
  }
  updateSpedizione(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteSpedizione(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  stampa(value): Observable<any> {
    return this.http.post(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/stampa`,
      JSON.stringify(value),
      { headers: this.header(),observe:'response',responseType:'blob'}).pipe();
  }
  spedisci(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/spedito`,
      JSON.stringify(value),
      this.options()).pipe();
  }

  spedire(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/spedire`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  pagaCorriere(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/pagacorriere`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  pagaLaera(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/pagalaera`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  pagaCliente(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/pagacliente`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  inviaSMS(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/sms`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  tariffe(value): Observable<any>{
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/tariffe`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  creaLDV(value): Observable<any>{
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/crea`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  tracking(value): Observable<any>{
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/tracking`,
      JSON.stringify(value), {headers: new HttpHeaders({'Content-Type': 'application/json',}),}).pipe();
  }
  stampaMagnifest(value): Observable<any> {
    return this.http.post(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/manifest`,
      JSON.stringify(value),
      { headers: this.header(),observe:'response',responseType:'blob'}).pipe();
  }

  creaRitiro(value: any) {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/task/spedizione/ritiro`,
      JSON.stringify(value), {headers: new HttpHeaders({'Content-Type': 'application/json',}),}).pipe();
  }
}
