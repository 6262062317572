import { RouteInfo } from "../layouts/horizontal-sidebar/horizontal-sidebar.metadata";

export const HORIZONTALROUTESTASK: RouteInfo[] = [
  {
    path: "",
    title: "Task",
    icon: "home",
    class: "has-arrow",
    ddclass:"",
    extralink: false,
    submenu: [
        {
          path: "task/riepilogogiornaliero",
          title: "Riepilogo giornaliero",
          icon: "mdi mdi-adjust",
          class: "",
          ddclass:"",
          extralink: false,
          submenu: [],
        }
    ],
  }
];
