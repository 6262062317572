import { RouteInfo } from '../../layouts/horizontal-sidebar/horizontal-sidebar.metadata';
import { LoggedUser } from '../Models/LoggedUser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';
import { ApiPathsService } from '../../api-paths.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<LoggedUser>;
  public currentUser: Observable<LoggedUser>;
  private isLogoutEnabled: boolean;


  constructor(private http: HttpClient,
    private apiPaths: ApiPathsService,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<LoggedUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.isLogoutEnabled = true;
  }
  public get currentUserValue(): LoggedUser {
    return this.currentUserSubject.value;
  }
login(username: string, password: string) {
    return this.http.post<any>(this.apiPaths.getBaseAPI() + '/auth/login', { username, password })
      .pipe(map(user => {
        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        user.authdata = window.btoa(username + ':' + password);
        user.username = username;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        console.log("UTENTE LOGGATO: ", user);
        return user;
      }));
  }


  logout() {
    const id = this.currentUserValue.id
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(["/Login"]);
    // remove user from remote and local storage to log user out
    this.http.post<any>(this.apiPaths.getBaseAPI() + '/auth/logout', {id})
      .pipe()
        .subscribe(
          () => {
              localStorage.removeItem('currentUser');
              this.currentUserSubject.next(null);
              this.router.navigate(["/Login"]);
          },
          error => {
              localStorage.removeItem('currentUser');
              this.currentUserSubject.next(null);
              this.router.navigate(["/Login"]);
           }
        );

    return;
  }

  public get logoutEnabled(): boolean {
    return this.isLogoutEnabled;
  }

  public set logoutEnabled(val: boolean) {
    this.isLogoutEnabled = val;
  }

  public get username(): string {
    return this.currentUserValue.username;
  }
  isAmministrazione() {
    return this.currentUserValue && this.currentUserValue.ruoliObject.find(x => x.id === "A") !== undefined;
  };


  recoverpwd(email): Observable<any> {
    return this.http.post<any>(this.apiPaths.getBaseAPI() + '/auth/recover', JSON.stringify({email: email}), {headers: new HttpHeaders({'Content-Type': 'application/json',}),})
      .pipe(map(data => {
        return data;
      }));
  }
  resetpwd(value): Observable<any> {
    return this.http.post<any>(this.apiPaths.getBaseAPI() + '/auth/reset', JSON.stringify(value),{headers: new HttpHeaders({'Content-Type': 'application/json',}),})
      .pipe(map(data => {
        return data;
      }));
  }
}
