import { ScadenzarioModule } from './Scadenzario/Scadenzario.module';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './Login/_guards/AuthGuard';
import { environment } from 'src/environments/environment';
import {TrackingComponent} from "./Spedizioni/tracking/tracking.component";


export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: environment.baseUrl, pathMatch: 'full' },
      {
        path: 'listautenti',
        loadChildren: () => import('./Admin/Admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard]
      },{
        path: 'task',
        loadChildren: () => import('./task/task.module').then(m => m.TaskModule),
        canActivate: [AuthGuard]
      },{
        path: 'dashboard',
        loadChildren: () => import('./shared/Shared.module').then(m => m.SharedModule),
        canActivate: [AuthGuard]
      },{
        path: 'progetti',
        loadChildren: () => import('./Progetti/Progetti.module').then(m => m.ProgettiModule),
        canActivate: [AuthGuard]
      }, {
        path: 'stampe',
        loadChildren: () => import('./Stampe/Stampe.module').then(m => m.StampeModule),
        canActivate: [AuthGuard]
      }, {
        path: 'interventi',
        loadChildren: () => import('./Interventi/Interventi.module').then(m => m.InterventiModule),
        canActivate: [AuthGuard]
      }, {
        path: 'scadenzario',
        loadChildren: () => import('./Scadenzario/Scadenzario.module').then(m => m.ScadenzarioModule),
        canActivate: [AuthGuard]
      }, {
        path: 'primanota',
        loadChildren: () => import('./Prima_Nota/Prima_Nota.module').then(m => m.Prima_NotaModule),
        canActivate: [AuthGuard]
      }, {
        path: 'spedizioni',
        loadChildren: () => import('./Spedizioni/Spedizioni.module').then(m => m.SpedizioniModule),
        canActivate: [AuthGuard]
      }, {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
      },
    ]

  },
  {
    path: 'Login',
    loadChildren: () => import('./Login/Login.module').then(m => m.LoginModule)
  },
  {
    path: 'tracking/:id',
    component: TrackingComponent,
  },
  {
    path: '**',
    redirectTo: environment.baseUrl
  }
];
