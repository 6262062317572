import { Attivita } from './../models/Attivita';
import { ClassicType } from './../../shared/models/ClassicType';
import { Azienda } from './../models/Azienda';
import { Ruolo } from './../models/Ruolo';
import { map } from 'rxjs/operators';
import { Utenti } from './../models/utenti';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPathsService } from './../../api-paths.service';
import { AuthenticationService } from './../../Login/_services/authentication.service';
import { Observable } from 'rxjs';
import { RitornoPaginazione } from 'src/app/shared/models/RitornoPaginazione';
import { Sede } from '../models/Sede';

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
      }),
    };
  }
  //#region personale
  getTuttiRuoliAsString(): Observable<string[]> {
    return this.http.get <any[]>(
      `${this.apiPaths.getBaseAPI()}/auth/roles`,
      this.options()).pipe(map((data) => {
        const elencoRuoli: string[] = [];
        data.forEach((ruolo) => {
          elencoRuoli.push(ruolo.role);
        })
        return elencoRuoli;
      }));
  }
  getTuttiRuoliAsObject(): Observable<Ruolo[]> {
    return this.http.get<any>(
      `${this.apiPaths.getBaseAPI()}/auth/roles`,
      this.options()).pipe(map((data) => {
        const elencoRuoli: Ruolo[] = [];
        data.forEach((ruolo) => {
          elencoRuoli.push({id:ruolo.id, label:ruolo.label});
        })
        return elencoRuoli;
      }));
  }
  getListaTuttoPersonale(value): Observable<RitornoPaginazione<Utenti[]>> {
    return this.http.post<RitornoPaginazione<Utenti[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/all`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        const elencoPersonale: RitornoPaginazione<Utenti[]> = new RitornoPaginazione<Utenti[]>();
        elencoPersonale.total = data.total;
        elencoPersonale.data = [];
            data.data.forEach((dipendente) => {
              elencoPersonale.data.push({
                id: dipendente.id,
                nome: dipendente.nome,
                cognome: dipendente.cognome,
                username: dipendente.username,
                password: undefined,
                email: dipendente.email,
                indirizzo: dipendente.indirizzo,
                cap: dipendente.cap,
                citta: dipendente.citta,
                provincia: dipendente.provincia,
                telefono: dipendente.telefono,
                cellulare: dipendente.cellulare,
                codice_fiscale: dipendente.codice_fiscale,
                ruoli: dipendente.ruoli,
                enabled: dipendente.enabled,
                fullName: dipendente.cognome + ' ' + dipendente.nome,
                files: dipendente.files,
                ruoliObject: dipendente.ruoliObject
              });
            });
            return elencoPersonale;
      }));
  }
  searchPersonale(value): Observable<RitornoPaginazione<Utenti[]>> {
    return this.http.post<RitornoPaginazione<Utenti[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/list`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        const elencoPersonale: RitornoPaginazione<Utenti[]> = new RitornoPaginazione<Utenti[]>();
        elencoPersonale.total = data.total;
        elencoPersonale.data = [];
        data.data.forEach((dipendente) => {
          elencoPersonale.data.push({
            id: dipendente.id,
            nome: dipendente.nome,
            cognome: dipendente.cognome,
            username: dipendente.username,
            password: undefined,
            email: dipendente.email,
            indirizzo: dipendente.indirizzo,
            cap: dipendente.cap,
            citta: dipendente.citta,
            provincia: dipendente.provincia,
            telefono: dipendente.telefono,
            cellulare: dipendente.cellulare,
            codice_fiscale: dipendente.codice_fiscale,
            ruoli: dipendente.ruoli,
            enabled: dipendente.enabled,
            fullName:dipendente.cognome + ' ' + dipendente.nome,
            files: dipendente.files,
            ruoliObject: dipendente.ruoliObject
          });
        });
        return elencoPersonale;
      }));
  }
  getListaPersonale(value): Observable<RitornoPaginazione<Utenti[]>> {
    return this.http.post<RitornoPaginazione<Utenti[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/list`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        const elencoPersonale: RitornoPaginazione<Utenti[]> = new RitornoPaginazione<Utenti[]>();
        elencoPersonale.total = data.total;
        elencoPersonale.data = [];
        data.data.forEach((dipendente) => {
          elencoPersonale.data.push(   {
            id: dipendente.id,
            nome: dipendente.nome,
            cognome: dipendente.cognome,
            username: dipendente.username,
            password: undefined,
            email: dipendente.email,
            indirizzo: dipendente.indirizzo,
            cap: dipendente.cap,
            citta: dipendente.citta,
            provincia: dipendente.provincia,
            telefono: dipendente.telefono,
            cellulare: dipendente.cellulare,
            codice_fiscale: dipendente.codice_fiscale,
            ruoli: dipendente.ruoli,
            enabled: dipendente.enabled,
            fullName: dipendente.cognome + ' ' + dipendente.nome,
            files: dipendente.files,
            ruoliObject: dipendente.ruoliObject
          });
        });
        return elencoPersonale;
      }));
  }
  getInfoPersonale(personale): Observable<Utenti> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/get`,
      JSON.stringify(personale),
      this.options()
    ).pipe(map((data) => {
        return data;
    }));
  }
  getInfoProfilo(personale): Observable<Utenti> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/profilo/get`,
      JSON.stringify(personale),
      this.options()
    ).pipe(map((data) => {
        return data;
    }));
  }
  addNewUser(dipendente: Utenti): Observable<any> {
    return this.http.post<Utenti>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/add`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  updateUser(dipendente: Utenti): Observable<any> {
    return this.http.post<Utenti>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/update`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  updateProfilo(dipendente: Utenti): Observable<any> {
    return this.http.post<Utenti>(`${this.apiPaths.getBaseAPI()}/anagrafica/profilo/update`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  deleteUser(dipendente: Utenti): Observable<any> {
    return this.http.post<Utenti>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/delete`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  restoreUser(dipendente: Utenti): Observable<any> {
    return this.http.post<Utenti>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/restore`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  //#endregion
  //#region aziende
  getListaAziende(paginazione): Observable<RitornoPaginazione<Azienda[]>> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/list`,
      JSON.stringify(paginazione),
      this.options())
      .pipe(map((data) => {
        const Elenco: RitornoPaginazione<Azienda[]> = new RitornoPaginazione<Azienda[]>();
        Elenco.total = data.total;
        Elenco.data = [];
        data.data.forEach((item) => {
          Elenco.data.push(item);
        });
        return Elenco;
      }));
  }
  GetListaCorrieri(paginazione): Observable<RitornoPaginazione<Azienda[]>> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/corrieri/list`,
      JSON.stringify(paginazione),
      this.options())
      .pipe(map((data) => {
        const Elenco: RitornoPaginazione<Azienda[]> = new RitornoPaginazione<Azienda[]>();
        Elenco.total = data.total;
        Elenco.data = [];
        data.data.forEach((item) => {
          Elenco.data.push(item);
        });
        return Elenco;
      }));
  }
  getInfoAziende(azienda): Observable<Azienda> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/get`,
      JSON.stringify(azienda),
      this.options()
    ).pipe(map((data) => {
        return data;
    }));
  }
  getRuoloClienti(): Observable<ClassicType[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/auth/roles`,
      this.options()
    ).pipe(map((data) => {
      console.log(data);
      return data;
    }));
  }
  getTipologieClienti(): Observable<ClassicType[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/types`,
      this.options())
      .pipe(map((data) => {
        const Elenco:ClassicType[] = [];
        data.forEach((item) => {
          Elenco.push(item);
        });
        return Elenco;
      }));
  }
  getTipologieAttivita(): Observable<ClassicType[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/types`,
      this.options())
      .pipe(map((data) => {
        const Elenco: ClassicType[] = [];
        data.forEach((item) => {
          Elenco.push({id: item.id.toString(), label: item.label});
        });
        return Elenco;
      }));
  }
  getTipologieClientiAsString(): Observable<String[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/types`,
      this.options())
      .pipe(map((data) => {
        const Elenco:string[] = [];
        data.data.forEach((item) => {
          Elenco.push(item.label);
        });
        return Elenco;
      }));
  }

  InviaSmsAttivita(id: number): Observable<String[]> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/sms`,
      JSON.stringify({id: id}),
      this.options())
      .pipe(map((data) => {
       return data;
      }));
  }
  GetListaAttivita(filtri: any): Observable<RitornoPaginazione<any>> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/list`,
      JSON.stringify(filtri),
      this.options())
      .pipe(map((data) => {
        return data;
      }));
  }

  updateCompany(value: Azienda): Observable<any> {
    return this.http.post<Utenti>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addNewCompany(value: Azienda): Observable<any> {
    return this.http.post<Utenti>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteCompany(value: Azienda): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/delete`,
    JSON.stringify(value),
    this.options()).pipe();
  }
  updateAttivita(value: Attivita): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addNewAttivita(value: Attivita): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteAttivita(value: Attivita): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region sedi
  getSediCliente(value) {
    return this.http.post<Sede[]>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sedi`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        return data;
      }));
  }
  getTipiSedi(): Observable<ClassicType[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sede/types`,
      this.options())
      .pipe(map((data) => {
        const Elenco: ClassicType[] = [];
        data.forEach((item) => {
          Elenco.push({ id: item.id.toString(), label: item.label });
        });
        return Elenco;
      }));
  }
  addNewSedi(value: Sede) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sede/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateSedi(value: Sede): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sede/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteSede(value: Sede): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sede/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion

}
